<template>
<div>
  <div><org-change @orgChange="orgChange"/></div>


<!-- 街道 -->
  <div>
    <div class="container">
      <title-cop  :title="`${isStreet ? '街道' : '社区'}维护更新数统计`"/>
      <div class="user_status"><total-number  :countList="changeNumList" :resCount="changeNumCount" :countName="'维护更新总数'" :unit="'个'"/></div>
    </div>
    <div class="container">
      <title-cop  :title="'各社工维护更新数统计'"/>
      <div class="user_age"><stack-bar v-if="stackBarShow" ref="stackBar" :dataList="changeNumInfoList" :labelName="nameList" /></div>
    </div>
  </div>
</div>
</template>

<script>
import orgChange from './components/orgChange.vue'
import SexProgress from './components/sex-progress.vue'
import titleCop from './components/title-cop'
import totalNumber from './components/total-Number'
import UserType from './components/userType.vue'
import widthBar from './components/widthBar.vue'
import lineCop from './components/line-cop.vue'
import heightBar from './components/heightBar.vue'
import stackBar from './components/stackBar.vue'
export default {
  components: {
    titleCop,
    totalNumber,
    SexProgress,
    widthBar,
    orgChange,
    UserType,
    lineCop,
    stackBar,
    heightBar
  },
  data () {
    return {
      orgId: 0,
      isStreet: true,
      stackBarShow: false,
      orgList: [],
      changeNumList: [],
      changeNumCount: 0,
      changeNumInfoList: [],
      nameList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    orgChange (id, isStreet) {
      this.orgId = id
      this.isStreet = isStreet
      this.init()
    },
    init () {
      this.getChangeNum()
      this.getChangeNumInfo()
    },
    getChangeNum () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/change/changeNum'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.orgId || this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let per1, per2, per3
          let count = data.data.place + data.data.user
          per1 = data.data.user ? parseInt((data.data.user / count) * 100) : 0
          per2 = data.data.place == 0 ? 0 : 100 - per1
          this.changeNumList = [
            {name: '更新人数', value: data.data.user, percent: per1 + '%'},
            {name: '场所更新数', value: data.data.place, percent: per2 + '%'},
          ]
          this.changeNumCount = count
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getChangeNumInfo () {
      this.stackBarShow = false
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/change/changeNumInfo'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.orgId || this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          data.data = data.data.sort((a, b) => (a.userNum + a.placeNum) - (b.userNum + b.placeNum))
          let changeNumInfoList = [{name: '更新人数', value: []}, {name: '更新场所数', value: []}]
          let nameList = data.data.map(item => item.name)
          changeNumInfoList[0].value = data.data.map(item => item.userNum)
          changeNumInfoList[1].value = data.data.map(item => item.placeNum)
          this.changeNumInfoList = changeNumInfoList
          this.nameList = nameList
          this.stackBarShow = true
          this.$nextTick(() => {
            this.$refs.stackBar.initChartBar()
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.topOrgBox {
  padding: 20px 30px;
  .topOrg {
    width: 100%;
    height: 74px;
    text-align: center;
    line-height: 74px;
    margin: 0;
    border-radius: 10px;
    background-color: #fff;
    .pull-icon {
        width: 10px;
    }
  }
}
.container {
  background-color: #fff;
  margin-bottom: 20px;
}
.split_line {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 2px solid #eee;
}
.user_status {
  height: 400px;
}
</style>
